var path = require('internals/path');
var has = require('internals/has');
var wrappedWellKnownSymbolModule = require('internals/well-known-symbol-wrapped');
var defineProperty = require('internals/object-define-property').f;

module.exports = function (NAME) {
  var Symbol = path.Symbol || (path.Symbol = {});
  if (!has(Symbol, NAME)) defineProperty(Symbol, NAME, {
    value: wrappedWellKnownSymbolModule.f(NAME)
  });
};
